import React from 'react';
import './helloworld.css';


const Helloworld = () => {

  return (
    <>
    <div className="tittle">
      <h1>PROGRAMACIÓN <br/>
     <span className="rose">Y </span><span className="rose">DISEÑO</span> WEB </h1> 
    </div>
    <div className="rectangle" />
    </>
  );

}


export default Helloworld
