
import './App.css';
 
import Personaldata from './components/personaldata/personaldata';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Personaldata />
      </header>
    </div>
  );
}

export default App;
