import './personaldata.css';
import Helloworld from '../helloworld/helloworld'
import BackLines from '../backlines/backlines'
import CheckHeart from '../checkHeart/checkHeart'

import { FaHtml5, FaCss3, FaReact, FaWordpressSimple, FaFigma, FaGithub, FaLinkedin, FaAt, FaBootstrap } from 'react-icons/fa';
import { SiJavascript } from 'react-icons/si';

function Personaldata() {
    return(
        <>
        <div className="fondo">
        
        <div className="personaldata">
         
               
            <span className="flex">
                <Helloworld />   
                <div className="subtitle">        
                <h2>Melisa Scime Mulet</h2>
                <div className="cora">
                <CheckHeart />
                </div>   
                </div>  

            </span>
            
            <div className="skills">
                <div className= "rectangleTwo"/>
                    <h3>SKILLS</h3>
                    <span className= "iconos">
                        <span className= "icono"><FaHtml5 /></span>
                        <span className= "icono"><FaCss3 /></span>
                        <span className= "icono"><SiJavascript /></span>
                        <span className= "icono"><FaReact /> </span>
                        <span className= "icono"><FaBootstrap /></span>
                        <span className= "icono"><FaWordpressSimple /></span>
                        <span className= "icono"><FaFigma /></span>
                    </span>
                   
            </div>
            <div className="contact">
                <div className= "rectangleTwo" />
                <h3>CONTACTO</h3>
                <span className= "iconos">
                <a href="https://github.com/melisci"  target="_blank" rel="noreferrer" className= "icono"><FaGithub /></a>
                <a href="https://www.linkedin.com/in/melisa-scime-mulet-8a731b22b/"  target="_blank" rel="noreferrer" className= "icono"><FaLinkedin /></a>
                <a href="mailto:melisascime@gmail.com"  target="_blank" rel="noreferrer" className= "icono"><FaAt /></a>
                </span>
            </div>
            <div className="movilHeart flexcd my">
            <CheckHeart />
            </div>
        <div className="fondeo">
        <BackLines />        
        </div>
    

        </div>
        </div>
        </>
    )
}
export default Personaldata;